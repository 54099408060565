import App from 'next/app';
import Head from 'next/head';
import Theme from '@nfs/ui-kit/Theme';
import { AppErrorBoundary } from '../components/layouts/app-error-boundary';

import '../styles/document.css';
import '../styles/quote/coverageToggle.css';
import '../styles/quote/riskScore.css';
import '../styles/quote/premium.css';
import '../styles/quote/progressList.css';

export default class Neptune extends App {
    componentDidMount() {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles) {
            jssStyles.parentElement.removeChild(jssStyles);
        }
    }

    render() {
        const { Component, pageProps } = this.props;

        return (
            <>
                <Head>
                    <meta
                        name="viewport"
                        content="minimum-scale=1, initial-scale=1, width=device-width"
                    />
                </Head>
                <Theme>
                    <AppErrorBoundary>
                        <Component {...pageProps} />
                    </AppErrorBoundary>
                </Theme>
            </>
        );
    }
}
