import React from 'react';
import PropTypes from 'prop-types';
import Box from '@nfs/ui-kit/Box';
import Typography from '@nfs/ui-kit/Typography';

import Layout from './basic';

export class AppErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }

    static getDerivedStateFromError(error) {
        return { error };
    }

    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.error) {
            let errorLabel = 'An error has occurred';
            let errorMessage = this.state.error.message;
            switch (errorMessage) {
                case 'Error: Unable to find an application matching that input':
                    errorLabel = '404';
                    errorMessage =
                        'The application you were looking for does not exist';
                    break;
                case 'Error: Cannot access application':
                    errorLabel = '401';
                    errorMessage = 'You do not have access to this application';
                    break;
                default:
                    break;
            }
            return (
                <Layout>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        style={{ height: '1200px' }}
                    >
                        <Box style={{ width: '1200px' }}>
                            <Box mt={4}>
                                <Typography
                                    variant="h2"
                                    component="div"
                                    align="center"
                                >
                                    {errorLabel}
                                </Typography>
                            </Box>
                            <Box mt={4}>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    align="center"
                                >
                                    {errorMessage}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Layout>
            );
        }

        return this.props.children;
    }
}

AppErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired
};
