import PropTypes from 'prop-types';
import getConfig from 'next/config';
import Head from 'next/head';
import Link from 'next/link';

import AppBar from '@nfs/ui-kit/AppBar';
import IconButton from '@nfs/ui-kit/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import { useEffect, useMemo, useState } from 'react';
import Router from 'next/router';

import Toolbar from '@nfs/ui-kit/Toolbar';

import { useQuery, gql } from '@apollo/client';

import { Tooltip, Typography } from '@material-ui/core';
import Logo from './logo';
import { withApollo } from '../../lib/apollo';
import LiveChatIcon from '../icons/livechat';
import GetLiveChatData from './liveChatId';
import Help from '../help';
import { getHelpTiles } from '../help/model';
import styles from './basic.module.scss';
import AssurantChat from './assurant-chat';
import { getHeadTitle } from '../../utils/helpers/PageHelper';
import { useLazyFindConversionConfigs } from '../../hooks/useFindConversionConfigs';

const { publicRuntimeConfig } = getConfig() ?? {};
const {
    NEXT_PUBLIC_CHAT_ENABLED,
    NEXT_PUBLIC_ENABLE_HELP_DIALOG,
    NEXT_PUBLIC_ALLSTATE_TENANTS,
    NEXT_PUBLIC_SITE_NAME
} = publicRuntimeConfig ?? {};

export const DEFAULT_LAYOUT_QUERY = gql`
    query DefaultLayoutQuery {
        userInfo: introspect {
            userName
            id
            userTenants {
                tenantCode
            }
        }
    }
`;

export const TENANT_LOOKUP_QUERY = gql`
    query TenantLookupQuery($hostname: String!, $defaultThemeName: String) {
        tenantByHostname: lookupTenantByHostname(
            hostname: $hostname
            defaultThemeName: $defaultThemeName
        ) {
            tenantCode
        }
    }
`;

const shouldEnableHelp =
    Boolean(NEXT_PUBLIC_ENABLE_HELP_DIALOG) &&
    NEXT_PUBLIC_ENABLE_HELP_DIALOG.toString().toLowerCase() === 'true';

const BasicLayout = props => {
    const { children } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hostname = useMemo(() => {
        return typeof window !== 'undefined' ? window.location.hostname : '';
    });

    const {
        data: defaultLayoutQueryData,
        loading: defaultLayoutQueryLoading
    } = useQuery(DEFAULT_LAYOUT_QUERY);

    const { data: tenantLookupQueryData } = useQuery(TENANT_LOOKUP_QUERY, {
        variables: {
            hostname
        }
    });

    const { userInfo } = defaultLayoutQueryData ?? {};
    const { tenantByHostname } = tenantLookupQueryData ?? {};

    const [helpDialog, setHelpDialog] = useState(false);
    const [helpTiles, setHelpTiles] = useState(getHelpTiles());
    const [helpTooltip, setHelpTooltip] = useState(false);
    const [lookupTenantCode, setLookupTenantCode] = useState('');
    const persistedTridentHelpKey =
        userInfo?.id && `trident-help:${userInfo.id}`;

    const contact = {
        phone: tenantByHostname?.metadata?.phone || '1-800-555-5555',
        email:
            tenantByHostname?.metadata?.email || 'CustomerService@myflood.com',
        callHours: 'Available M-F from 8AM to 8PM ET',
        emailHours: '',
        liveChatHours: 'Available M-F from 8AM to 8PM ET'
    };

    if (
        (userInfo?.userTenants?.tenantCode || tenantByHostname?.tenantCode) &&
        !lookupTenantCode
    ) {
        setLookupTenantCode(
            userInfo?.userTenants?.tenantCode || tenantByHostname?.tenantCode
        );
    }

    // TODO [10/14/21] pkamalakanthan: Switch to findTenantConfig once head codes are available
    const floodInsuranceResources =
        tenantByHostname?.hostNames?.metadata?.tridentHelpUri ||
        'https://edu.myflood.com/';

    const setCache = cacheKey => {
        if (cacheKey) {
            // Visited cache converted into an object
            const visitedCache = JSON.parse(
                window.localStorage.getItem(cacheKey)
            );
            if (!visitedCache) {
                // Setting visited tiles to cache
                const visited = {};
                helpTiles.forEach(tile => {
                    visited[tile.key] = !!tile.visited;
                });
                window.localStorage.setItem(cacheKey, JSON.stringify(visited));
            } else {
                // First launch with localStorage entry
                setHelpTiles(
                    helpTiles.map(tile => {
                        return {
                            ...tile,
                            visited: visitedCache[tile.key]
                        };
                    })
                );
            }
            return JSON.parse(window.localStorage.getItem(cacheKey));
        }
        return null;
    };

    // Effect block for changing Help Tiles based on WYO
    useEffect(() => {
        const isAllstateTenant = userInfo?.userTenants.some(({ tenantCode }) =>
            NEXT_PUBLIC_ALLSTATE_TENANTS?.includes(tenantCode)
        );
        if (isAllstateTenant) {
            setHelpTiles(getHelpTiles('allstate'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo?.userTenants, tenantByHostname?.tenantCode]);

    // Effect block for opening Trident Help dialog on launch & restoring tiles from cache
    useEffect(() => {
        if (shouldEnableHelp && persistedTridentHelpKey) {
            if (!window.localStorage.getItem(persistedTridentHelpKey)) {
                setHelpDialog(true);
            } else {
                setCache(persistedTridentHelpKey);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldEnableHelp, persistedTridentHelpKey]);

    const livechatData = useMemo(() => {
        if (userInfo?.userTenants || tenantByHostname?.__typename) {
            const tenants = userInfo?.userTenants
                ? userInfo.userTenants
                : [{ tenantCode: tenantByHostname?.tenantCode }];
            return GetLiveChatData(tenants);
        }
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo?.userTenants, tenantByHostname?.tenantCode]);

    const [shouldEnableChat, setShouldEnableChat] = useState(false);

    const [
        findConversionConfigs,
        { data: conversionConfigData, loading: conversionConfigDataLoading }
    ] = useLazyFindConversionConfigs();

    useEffect(() => {
        if (!conversionConfigDataLoading) {
            const outboundConversionConfigs =
                conversionConfigData?.listOfRestrictedTenantConversionConfigs;

            const isChatBlocked = outboundConversionConfigs
                ? outboundConversionConfigs.disableLiveChat
                : false;

            setShouldEnableChat(
                Boolean(NEXT_PUBLIC_CHAT_ENABLED) &&
                    NEXT_PUBLIC_CHAT_ENABLED.toString().toLowerCase() ===
                        'true' &&
                    !isChatBlocked
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversionConfigDataLoading, conversionConfigData]);

    useEffect(() => {
        if (
            lookupTenantCode &&
            findConversionConfigs &&
            !conversionConfigData &&
            !conversionConfigDataLoading
        ) {
            findConversionConfigs({
                variables: {
                    loggedInTenantCodes: [lookupTenantCode]
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        lookupTenantCode,
        findConversionConfigs,
        conversionConfigDataLoading,
        conversionConfigData
    ]);

    /**
     * Open LiveChat window
     */
    const handleLiveChat = () => {
        window.open(livechatData.url, 'LiveChat', 'width=500,height=810');
    };

    return (
        <>
            <Head>
                <title>{getHeadTitle()}</title>
                <meta name="Description" content="Error message" />
            </Head>
            <AppBar position="fixed" elevation={0} className="Appbar">
                <Toolbar>
                    <Link href="/">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="Appbar-link">
                            <Logo />
                        </a>
                    </Link>
                    {shouldEnableHelp && (
                        <Tooltip
                            classes={{ tooltip: styles.toolTip }}
                            variant="caption"
                            display="block"
                            title={<Typography>Get Help</Typography>}
                            open={helpTooltip}
                            onOpen={() => setHelpTooltip(true)}
                            onClose={() => setHelpTooltip(false)}
                            style={{
                                position: 'absolute',
                                right: '130px'
                            }}
                        >
                            <IconButton
                                className="Appbar-account"
                                color="inherit"
                                data-testid="trident-help-button"
                                onClick={() => {
                                    setHelpDialog(true);
                                }}
                            >
                                <HelpIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    {shouldEnableChat && livechatData && (
                        <>
                            {livechatData.provider !== 'livePerson' ? (
                                <IconButton
                                    aria-label="Account menu"
                                    onClick={handleLiveChat}
                                    data-testid={livechatData.id}
                                    className="Appbar-account"
                                    color="inherit"
                                    style={{
                                        position: 'absolute',
                                        right: '70px'
                                    }}
                                >
                                    <LiveChatIcon />
                                </IconButton>
                            ) : (
                                <>
                                    <div
                                        data-testid="assurant"
                                        style={{
                                            position: 'absolute',
                                            right: '70px'
                                        }}
                                    >
                                        {!defaultLayoutQueryLoading && (
                                            <AssurantChat
                                                pathname={Router.pathname}
                                            />
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Toolbar>
            </AppBar>
            <Help
                data-testid="trident-help"
                open={helpDialog}
                onClose={() => {
                    setHelpDialog(false);
                    setHelpTooltip(true);
                    setCache(persistedTridentHelpKey);
                }}
                onBack={activeTile => {
                    if (persistedTridentHelpKey) {
                        let visited = JSON.parse(
                            window.localStorage.getItem(persistedTridentHelpKey)
                        );
                        if (!visited) {
                            visited = setCache(persistedTridentHelpKey);
                        }
                        visited[activeTile] = true;
                        window.localStorage.setItem(
                            persistedTridentHelpKey,
                            JSON.stringify(visited)
                        );
                    }
                }}
                tiles={helpTiles}
                setTiles={setHelpTiles}
                title="Find the help you need to get started below"
                contact={contact}
                shouldEnableChat={shouldEnableChat}
                handleChat={handleLiveChat}
                floodInsuranceResources={floodInsuranceResources}
            />
            {children}
            <footer className="App-footer">
                &copy; Copyright {new Date().getFullYear()}.{' '}
                {NEXT_PUBLIC_SITE_NAME}.
            </footer>
        </>
    );
};

BasicLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export { BasicLayout };

export default withApollo({ ssr: true })(BasicLayout);
